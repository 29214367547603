/* Estilos para o componente MoegaDisplay */
.moega-display {
    background-color: #e0e0e0;
    padding:   20px;
    border-radius:   10px;
    box-shadow:   0   4px   6px rgba(0,   0,   0,   0.1);
    transition: transform   0.3s ease-out;
    margin-bottom:   20px;
    flex:   1   0 auto;
    min-width:   150px;
    background-color: #d0d0d0;
    box-sizing: border-box;
}

.moega-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:   100%;
}

.moega-title {
    font-weight: bold;
    font-size:   4rem; /* Aumentado o tamanho da fonte para títulos */
    margin-bottom:   10px;
    color: #333; /* Cor de texto escura para melhor contraste */
}

.moega-item {
    font-size:   2.5rem; /* Aumentado o tamanho da fonte para itens da lista */
    margin-bottom:   5px;
    color: #333; /* Cor de texto escura para melhor contraste */
}

/* Estilos para o componente ChamadaResumo */
.chamada-resumo {
    background-color: #dff0d8;
    padding:   10px;
    border-radius:   5px;
    box-shadow:   0   4px   6px rgba(0,   0,   0,   0.1);
    margin-bottom:   10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chamada-resumo div {
    margin-bottom:   5px;
}

.chamada-resumo strong {
    font-weight: bold;
    font-size:   2.5rem; /* Aumentado o tamanho da fonte para textos fortes */
    color: #333; /* Cor de texto escura para melhor contraste */
}

/* Ícones para representar diferentes tipos de chamadas */
.chamada-resumo .icon {
    width:   24px;
    height:   24px;
    margin-right:   5px;
}

/* ... (restante do código CSS) */

.chamada-container {
    display: flex;
    flex-direction: column;
    height:   100vh;
    background-color: #f5f5f5;
    padding:   20px;
    box-shadow:   0   2px   4px rgba(0,   0,   0,   0.1);
}

.chamada-alert {
    background-color: #f8d7da; /* Cor de fundo de alerta */
    color: red; /* Cor do texto de alerta */
    font-size:  15rem; /* Tamanho de fonte maior */
    font-weight: bold; /* Texto em negrito */
    text-align: center; /* Centraliza o texto horizontalmente */
    width: 100%;
}

.chamada-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:   10px;
    background-color: #333;
    color: white;
    border-radius:   5px;
    margin-bottom:   20px;
}

.chamada-title {
    font-size:   3rem; /* Aumentado o tamanho da fonte para o título principal */
    margin:   0;
    color: #fff; /* Cor de texto branca para melhor contraste */
}

.chamada-content {
    display: flex;
    flex-direction: column;
    gap:   20px;
}

.chamada-current-info {
    display: flex;
    gap:   10px;
    width:   100%;
    padding:   0;
    margin:   0;
    flex-direction: row;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
}

/* CSS para fazer o componente piscar */
.chamada-current-flashing {
    animation: flashing  1s infinite;
}

@keyframes flashing {
    0%,  100% { opacity:  1; }
    50% { opacity:  0; }
}

.chamada-current-vehicle, .chamada-current-driver, .chamada-current-type {
    font-size:   6rem; /* Aumentado o tamanho da fonte para informações de chamada atual */
    margin-bottom:   5px;
    color: #333; /* Cor de texto escura para melhor contraste */
}

.chamada-current, .chamada-latest, .chamada-moegas {
    background-color: white;
    padding:   20px;
    border-radius:   5px;
    box-shadow:   0   2px   4px rgba(0,   0,   0,   0.1);
}

.chamada-current-title, .chamada-latest-title, .chamada-moegas-title {
    font-size:   2.5rem; /* Aumentado o tamanho da fonte para títulos secundários */
    margin-bottom:   10px;
    color: #333; /* Cor de texto escura para melhor contraste */
}

.chamada-current-info, .chamada-latest-list, .chamada-moegas-grid {
    display: flex;
    gap:   10px;
    width:   100%;
    padding:   0;
    margin:   0;
    flex-direction: row;
}

/* Chamada Atual destacada */
.chamada-current {
    background-color: #dff0d8; /* Cor de fundo para destacar a chamada atual */
    padding:   20px;
    border-radius:   5px;
    box-shadow:   0   4px   6px rgba(0,   0,   0,   0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza o conteúdo horizontalmente */
    justify-content: center; /* Centraliza o conteúdo verticalmente */
}

.chamada-current-title {
    font-size:   3rem; /* Aumentado o tamanho da fonte para o título da chamada atual */
    margin-bottom:   10px;
    color: #333; /* Cor de texto escura para melhor contraste */
}

.chamada-current-info {
    display: flex;
    gap:   10px;
    width:   100%;
    padding:   0;
    margin:   0;
    flex-direction: row;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
}

.chamada-current-vehicle, .chamada-current-driver, .chamada-current-type {
    font-size:   3rem; /* Aumentado o tamanho da fonte para informações de chamada atual */
    margin-bottom:   5px;
    color: #333; /* Cor de texto escura para melhor contraste */
}

.chamada-latest-list {
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

.chamada-moegas-grid {
    display: flex;
    justify-content: space-between;
    gap:   10px;
    width:   100%;
    padding:   0;
    margin:   0;
    background-color: #f0f0f0;
    flex-direction: row;
}

/* ... (restante do código CSS) */

.chamada-resumo-vehicle, .chamada-resumo-driver, .chamada-resumo-type {
    font-size:   2.5rem; /* Reduzido o tamanho da fonte para informações de chamada */
    margin-bottom:   5px;
}

.chamada-latest-list {
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

.chamada-moegas-grid {
    display: flex;
    justify-content: space-between;
    gap:   10px;
    width:   100%;
    padding:   0;
    margin:   0;
    background-color: #f0f0f0;
    flex-direction: row;
}